<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="4" xl="3">
      <v-sheet rounded="lg">
        <v-expansion-panels class="pa-1" v-model="panel" flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h5 font-weight-light">
              <div><v-icon left class="mb-1 ml-n1">fas fa-filter</v-icon>Filtrar</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="form">
                <div>
                  <v-text-field
                    v-model.trim="palabras_claves"
                    label="Palabras claves"
                  ></v-text-field>
                  <v-select
                    v-model="provincias_seleccionadas"
                    :items="nombre_provincias"
                    :rules="[rules.max]"
                    attach
                    chips
                    label="Provincia"
                    multiple
                  ></v-select>
                </div>
                <div class="d-flex justify-center mt-2">
                  <v-btn
                    class="col-6 mr-2"
                    color="orange"
                    dark
                    @click="limpiar"
                  >
                    Limpiar
                  </v-btn>
                  <v-btn
                    class="col-6 ml-2"
                    color="info"
                    @click="buscar"
                  >
                    Buscar
                    <v-icon right small>fas fa-search</v-icon>
                  </v-btn>
                </div>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </v-col>

    <v-col md="7" xl="6">
      <v-sheet
        min-height="70vh"
        rounded="lg"
      >
        <v-col><h1 class="text-h4 font-weight-light">Mis postulaciones</h1></v-col>
        <v-col>
          <div v-if="user.postulaciones.length === 0">
            <v-alert
              border="left"
              outlined
              dense
              type="info"
            >
              Usted todavia no se ha postulado en ninguna búsqueda
            </v-alert>
            <div class="text-center mt-6">
              <v-btn
                color="info"
                to="/"
              >Ver Búsquedas activas</v-btn>
            </div>
          </div>
          <div v-else-if="load" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else-if="postulaciones.length > 0">
            <v-list class="pt-0">
              <v-list-item
                v-for="(item, index) in postulaciones"
                :key="index"
                class="py-1 px-4"
              >
                <ConvocatoriaCard :item="item" />
              </v-list-item>
            </v-list>
          </div>
          <div v-else class="d-flex justify-center">
            <v-alert
              border="left"
              outlined
              dense
              type="warning"
            >
              No se encontraron postulaciones para los filtros seleccionados
            </v-alert>
          </div>
        </v-col>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { db } from '../firebase'
import ConvocatoriaCard from '../components/ConvocatoriaCard'

export default {
  data() {
    return {
      panel: 0,
      load: false,
      palabras_claves: '',
      nombre_provincias: [],
      provincias_seleccionadas: [],
      postulaciones: [],
      rules: { max: value => value.length <= 10 || 'Máximo 10 provincias' },
    }
  },
  async created() {
    this.provincias.forEach(provincia => {
      this.nombre_provincias.push(provincia.nombre)
    })
    this.get_postulaciones()
  },
  components: {
    ConvocatoriaCard
  },
  computed: {
    ...mapState(['user', 'provincias']),
  },
  methods: {
    async get_postulaciones() {
      this.load = true
      let postulaciones = []

      for (let index = 0; index < this.user.postulaciones.length; index++) {

        const postulacion = this.user.postulaciones[index]

        if (postulacion.estado !== 'Cancelada por el postulante') {

          await db.collection('convocatorias').doc(postulacion.convocatoria).get()
            .then((doc) => {
              if (doc.exists) {
                let convocatoria = doc.data()

                  if (this.provincias_seleccionadas.length > 0) {
                    if (this.provincias_seleccionadas.find((prov => prov === convocatoria.provincia)) === undefined) return
                  }
                  if (convocatoria.activa) {
                    convocatoria.etapa = 'En proceso'
                  } else {
                    convocatoria.etapa = 'Finalizada'
                  }
                  convocatoria.id = doc.id
                  convocatoria.fecha_desde_format = this.formatDate(convocatoria.fecha.desde)
                  convocatoria.fecha_hasta_format = this.formatDate(convocatoria.fecha.hasta)
                  convocatoria.fecha_post = this.formatDate(postulacion.fecha_post)
                  postulaciones.push(convocatoria)

              }
            }).catch((error) => {
                console.log(error)
            })
        }
      }

      this.postulaciones = postulaciones.filter(this.filter_palabras_claves)
      this.postulaciones.reverse()
      this.load = false
    },

    buscar () {
      if (this.$refs.form.validate()) {
        this.get_postulaciones()
      }
    },

    filter_palabras_claves(convocatoria) {
      const palabras_claves = this.palabras_claves.toLowerCase()
      const busqueda = convocatoria.busqueda.toLowerCase()
      const mision = convocatoria.puesto.data.mision.toLowerCase()
      const puesto = convocatoria.puesto.text.toLowerCase()

      return busqueda.indexOf(palabras_claves) >= 0 || mision.indexOf(palabras_claves) >= 0 || puesto.indexOf(palabras_claves) >= 0
    },

    limpiar() {
      this.palabras_claves = ''
      this.provincias_seleccionadas = []
    },

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  }
}
</script>